<template>
  <popupcommon :iconClose="iconClose">
    <div class="popup-main__villa" v-if="dataPopup">
      <div class="popup__slide">
        <swiper
          :modules="modules"
          :pagination="{
            clickable: true,
          }"
          :loop="true"
          :slides-per-view="1"
          v-if="dataPopup.slides"
        >
          <swiper-slide v-for="(img, index) in dataPopup.slides" :key="index"
            ><img :src="img.slide" alt=""
          /></swiper-slide>
        </swiper>
      </div>
      <div class="popup__detail">
        <div class="title" data-aos="fade-up" data-aos-duration="10000">
          <h1>{{ dataPopup[locale].name }}</h1>
          <p class="des">
            {{ dataPopup[locale].description }}
          </p>
        </div>
        <div class="list-box" data-aos="fade-up">
          <div
            class="box"
            v-for="(box, index2) in dataPopup.icons"
            :key="index2"
          >
            <div class="box__icon">
              <img :src="box.icon" alt="" />
            </div>
            <div class="box__title">
              {{ box[locale].description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </popupcommon>
</template>
<script>
import { Pagination, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import popupcommon from "@/components/popup/popupcommon.vue";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import { mapGetters } from 'vuex';
export default {
  components: { Swiper, SwiperSlide, popupcommon },
  props: {
    dataPopup: null,
  },
  data() {
    return {
      iconClose: true,
    };
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   console.log("slide change");
    // };
    return {
      // onSwiper,
      // onSlideChange,
      modules: [Pagination, EffectFade],
    };
  },
  methods: {},
};
</script>
