import http from "@/http/http-common";
const resource = "/api/frontend";
class ContactService {
    sendContact(data) {
        return http.post(`${resource}/contactUs`, data);
    }
    // getId(id) {
    //     return http.get(`/abc/${id}`);
    // }
    // put(data) {
    //     return http.put(`/abc/`, data);
    // }
    // delete(id) {
    //     return http.delete(`abc/${id}`);
    // }

}

export default new ContactService();