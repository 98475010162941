import http from "@/http/http-common";
const resource = "/api/frontend";
class VillaService {
    villaType() {
        return http.get(`${resource}/villaTypes`);
    }
    villasDetail(id) {
        return http.get(`${resource}/getVillasByType?type_id=${id}`);
    }

}

export default new VillaService();