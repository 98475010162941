<template>
  <div class="villa-rent">
    <div class="banner-top">
      <div class="banner-top__bg">
        <img :src="villa?.header?.header" alt="" />
      </div>
    </div>
    <div class="category pc">
      <img src="../assets/img/logo/logo-category.svg" alt="" />
      <a class="before" href="#">Trang chủ</a>
      <span class="tag-mid">/</span>
      <a class="after" href="#">Biệt Thự</a>
    </div>
    <div class="villa-main">
      <div class="villa-main__title" v-if="villa">
        <h5 data-aos="fade-up">{{ villa?.header[locale].name }}</h5>
        <h1 data-aos="fade-up" v-html="villa?.header[locale].title"></h1>
        <p
          class="des"
          data-aos="fade-up"
          v-html="villa?.header[locale].content"
        ></p>
      </div>
      <div class="villa-main__list-villa" v-if="villas">
        <div
          v-for="(item, index) in villas"
          :key="index"
          :class="(index + 1) % 2 ? 'item item-gray' : 'item'"
        >
          <div v-if="(index + 1) % 2" class="item__content" data-aos="fade-up">
            <h3 class="title" v-html="item[locale].name"></h3>
            <p class="des" v-html="item[locale]?.description"></p>
            <div class="info">
              <div class="list-box">
                <div
                  class="box"
                  v-for="(box, index2) in item.icons.length > 4
                    ? item.icons.slice(0, 4)
                    : item.icons"
                  :key="index2"
                >
                  <div class="box__icon">
                    <img :src="box.icon" alt="" />
                  </div>
                  <div
                    class="box__title"
                    v-html="box[locale]?.description"
                  ></div>
                </div>
              </div>
              <div class="view-detail" @click="activePopup(item)">
                <a href="#">
                  <div class="icon">
                    <img
                      src="../assets/img/icons/arrow-right-orange.svg"
                      alt=""
                    />
                  </div>
                  <span class="text-uppercase">{{
                    trans("frontend.button.detail")
                  }}</span>
                </a>
              </div>
            </div>
          </div>
          <div class="item__bg" data-aos="fade-up" @click="activePopup(item)">
            <img :src="item.background" alt="" />
          </div>
          <div
            v-if="!((index + 1) % 2)"
            class="item__content"
            data-aos="fade-up"
          >
            <h3 class="title" v-html="item[locale].name"></h3>
            <p class="des" v-html="item[locale].description"></p>
            <div class="info">
              <div class="list-box">
                <div
                  class="box"
                  v-for="(box, index2) in item.icons.length > 4
                    ? item.icons.slice(0, 4)
                    : item.icons"
                  :key="index2"
                >
                  <div class="box__icon">
                    <img :src="box.icon" alt="" />
                  </div>
                  <div
                    class="box__title"
                    v-html="box[locale].description"
                  ></div>
                </div>
              </div>
              <div class="view-detail" @click="activePopup(item)">
                <a href="#">
                  <div class="icon">
                    <img
                      src="../assets/img/icons/arrow-right-orange.svg"
                      alt=""
                    />
                  </div>
                  <span class="text-uppercase">{{
                    trans("frontend.button.detail")
                  }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="villa-main__register" data-aos="fade-up">
        <h1 class="title text-capitalize">
          {{ trans("frontend.form.title.villa") }}
        </h1>
        <form @submit="submitVisit" ref="formVisit">
          <div class="info">
            <input
              type="text"
              name=""
              id=""
              :placeholder="trans('frontend.form.nick_name')"
              v-model="form.nick_name"
            />
            <input
              type="text"
              name=""
              id=""
              :placeholder="trans('frontend.form.full_name')"
              v-model="form.full_name"
            />
            <input
              type="text"
              name=""
              id=""
              :placeholder="trans('frontend.form.phone')"
              v-model="form.phone"
            />
            <input
              type="text"
              name=""
              id=""
              :placeholder="trans('frontend.form.email')"
              v-model="form.email"
            />
          </div>
          <div class="content">
            <textarea
              type="text"
              name=""
              id=""
              :placeholder="trans('frontend.form.content')"
              v-model="form.content"
            />
          </div>
          <div class="submit">
            <p class="note">{{ trans("frontend.form.required") }}</p>
            <button type="submit">
              <img src="../assets/img/icons/arrow-right-white.svg" alt="" />
              <span class="text-uppercase">{{
                trans("frontend.button.send to")
              }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <popupVilla :dataPopup="dataPopup" />
  </div>
</template>
<script>
/* global $ */
import popupVilla from "@/components/popup/popupVilla.vue";
import ContactService from "@/services/ContactService";
import VillaService from "@/services/VillaService";
import { mapGetters } from "vuex";
export default {
  components: {
    popupVilla,
  },
  data() {
    return {
      submit: false,
      villa: "",
      villas: [],
      dataPopup: "",
      form: {
        email: "",
        phone: "",
        full_name: "",
        nick_name: "",
        content: "",
        type: 2,
      },
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.villaDetail();
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    activePopup(item) {
      this.dataPopup = item;
      $("#popupcommon").removeClass("hide");
      $("#popupcommon").addClass("active");
    },
    // async villaType() {
    //   await VillaService.villaType(this.$route.query.id)
    //     .then((resp) => {
    //       this.dataImg = resp.data.data;
    //     })
    //     .catch(() => {});
    // },
    async villaDetail() {
      await VillaService.villasDetail(1)
        .then((resp) => {
          this.villa = resp.data.data;
          this.villas = resp.data.data?.villas;
        })
        .catch(() => {});
    },
    submitVisit() {
      let formDatas = new FormData();
      for (var key in this.form) {
        formDatas.append(key, this.form[key]);
      }
      ContactService.sendContact(formDatas)
        .then(() => {
          this.submit = true;
          this.$refs.formVisit.reset();
          this.$toast.fire({
            icon: "success",
            title: "Gửi Thành Công",
          });
        })
        .catch(() => {
          this.submit = false;
          this.$toast.fire({
            icon: "error",
            title: "Gửi Thất Bại",
          });
        });
    },
  },
};
</script>
