<template>
  <div
    :class="[opacity ? 'popup-opacity' : '', 'hide popup-common']"
    :id="attr ? 'popupcommon' + attr : 'popupcommon'"
  >
    <div class="popup-main">
      <slot></slot>
      <div class="popup-main__close" @click="closePop()">
        <img v-if="iconClose" src="../../assets/img/icons/close.svg" alt="" />
        <img v-else src="../../assets/img/icons/close-black.svg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
/* global $ */
export default {
  props: {
    iconClose: null,
    opacity: null,
    attr: null
  },
  data() {
    return {
    };
  },
  methods: {
    closePop() {
      $(".popup-common").removeClass("active");
      $(".popup-common").addClass("hide");
    },
  },
};
</script>
